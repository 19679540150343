import axios from 'axios';
import React, { useState } from 'react';
import './CreateDeckPopup.css'; // Importing CSS for the popup

interface CreateDeckPopupProps {
  onClose: () => void; // Function to close the popup
  onSubmit: () => void; // Function to submit the popup
  selectedLanguage: string; // Selected language passed from parent component
}

const CreateDeckPopup: React.FC<CreateDeckPopupProps> = ({
  onClose,
  onSubmit,
  selectedLanguage,
}) => {
  const [deckName, setDeckName] = useState<string>(''); // State for deck name
  const [newLimit, setNewLimit] = useState<number | undefined>(undefined); // State for new limit
  const [reviewLimit, setReviewLimit] = useState<number | undefined>(undefined); // State for review limit
  const [showAdvancedSettings, setShowAdvancedSettings] =
    useState<boolean>(false); // State for showing advanced settings

  const handleCreateDeck = async () => {
    if (!selectedLanguage || !deckName) {
      alert('Please fill in all required fields');
      return;
    }

    try {
      // Define the payload with optional new_limit and review_limit
      const payload = {
        name: deckName,
        language_code: selectedLanguage,
        new_limit: showAdvancedSettings ? newLimit : undefined,
        review_limit: showAdvancedSettings ? reviewLimit : undefined,
      };

      await axios.post('/api/decks/create', payload);
      // Close the popup after successful creation
      onSubmit();
    } catch (error) {
      console.error('Error creating deck:', error);
      onClose();
    }
  };

  const toggleAdvancedSettings = () => {
    setShowAdvancedSettings((prev) => !prev);
  };

  return (
    <div className="phn-popup-overlay">
      <div className="phn-white-card phn-popup" style={{ width: '500px' }}>
        <h2>Create New Deck</h2>
        <div className="phn-popup-form">
          <label htmlFor="name">
            Name
            <input
              id="name"
              type="text"
              value={deckName}
              onChange={(e) => setDeckName(e.target.value)}
            />
          </label>

          {showAdvancedSettings && (
            <>
              <label htmlFor="newLimit">
                Daily New Card Limit
                <input
                  id="new_limit"
                  type="text"
                  value={newLimit !== undefined ? newLimit.toString() : '5'}
                  onChange={(e) => {
                    const value = e.target.value.trim(); // Trim whitespace
                    if (value === '' || isNaN(parseInt(value))) {
                      setNewLimit(0); // Clear the state if input is empty or NaN
                    } else {
                      setNewLimit(parseInt(value)); // Parse and set the numeric value
                    }
                  }}
                />
              </label>

              <label htmlFor="reviewLimit">
                Daily Review Card Limit
                <input
                  id="reviewLimit"
                  type="text"
                  value={
                    reviewLimit !== undefined ? reviewLimit.toString() : '50'
                  }
                  onChange={(e) => {
                    const value = e.target.value.trim(); // Trim whitespace
                    if (value === '' || isNaN(parseInt(value))) {
                      setReviewLimit(0); // Clear the state if input is empty or NaN
                    } else {
                      setReviewLimit(parseInt(value)); // Parse and set the numeric value
                    }
                  }}
                />
              </label>
            </>
          )}
          <div className="phn-popup-button-container">
            <button
              className="create-deck-button phn-button"
              onClick={handleCreateDeck}
            >
              Create
            </button>
            <button
              className="create-deck-button phn-cancel-button"
              onClick={toggleAdvancedSettings}
            >
              {showAdvancedSettings ? 'Hide Advanced' : 'Advanced'}
            </button>
            <button
              className="create-deck-button phn-cancel-button"
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateDeckPopup;
