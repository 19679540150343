import axios from 'axios';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FaTrash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useUser } from '../../../context/UserContext';
import { StoryData } from '../../../interfaces/stories';
import ConfirmDeletePopup from '../../../popups/confirmDeletePopup.tsx/ConfirmDeletePopup';
import CreateStoryPopup from '../../../popups/createStory/CreateStoryPopup';
import './StoriesPage.css';

const StoriesPage: React.FC = () => {
  const { userData } = useUser();
  const languageCode = userData?.learning_language_code;
  const [stories, setStories] = useState<StoryData[]>([]);
  const [showCreatePopup, setShowCreatePopup] = useState(false);
  const [storyToDelete, setStoryToDelete] = useState<StoryData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const intervalIdsRef = useRef<{ [key: number]: NodeJS.Timeout }>({});
  const navigate = useNavigate();

  const fetchStories = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `/api/stories/language_code/${languageCode}`
      );
      const data = response.data;
      setStories(data);
    } catch (error) {
      console.error('Error fetching sorted story data:', error);
    } finally {
      setLoading(false);
    }
  }, [languageCode]);

  useEffect(() => {
    fetchStories();
  }, [fetchStories]);

  const fetchStoryById = useCallback(async (storyId: number) => {
    try {
      const response = await axios.get(`/api/stories/${storyId}`);

      const story = response.data;
      setStories((prevStories) =>
        prevStories.map((s) => (s.id === story.id ? story : s))
      );

      if (story.status === 'Complete' && intervalIdsRef.current[story.id]) {
        toast.dismiss('creating-story');
        toast.success('Story is ready!', { autoClose: 3000 });
        clearInterval(intervalIdsRef.current[story.id]);
        delete intervalIdsRef.current[story.id];
      } else if (story.status === 'Error') {
        toast.dismiss('creating-story');
        toast.error('Failed to create story. Please try again.', {
          autoClose: 5000,
        });
        clearInterval(intervalIdsRef.current[story.id]);
        delete intervalIdsRef.current[story.id];
      }
    } catch (error) {
      console.error('Error fetching the story:', error);
    }
  }, []);

  useEffect(() => {
    stories.forEach((story) => {
      if (
        story.status !== 'Complete' &&
        story.status !== 'Error' &&
        !intervalIdsRef.current[story.id]
      ) {
        intervalIdsRef.current[story.id] = setInterval(() => {
          fetchStoryById(story.id);
        }, 5000);
      }
    });

    return () => {
      Object.values(intervalIdsRef.current).forEach(clearInterval);
      intervalIdsRef.current = {};
    };
  }, [fetchStoryById, stories]);

  const handleCreateStoryClick = () => {
    setShowCreatePopup(true);
  };

  const confirmDeleteStory = async () => {
    if (storyToDelete) {
      try {
        await axios.delete(`/api/stories/delete/${storyToDelete.id}`);
        fetchStories();
        setStoryToDelete(null);
      } catch (error) {
        console.error('Error deleting the story:', error);
      }
    }
  };

  const submitStory = async () => {
    setShowCreatePopup(false);
    await fetchStories();
    toast.info('Story creation in progress...', {
      autoClose: false,
      toastId: 'creating-story',
    });
  };

  return (
    <>
      <div className="phn-page-container">
        <h1>Stories</h1>
        <div className="phn-main-content">
          <button className="phn-button" onClick={handleCreateStoryClick}>
            Create Story
          </button>
          {loading ? (
            <div className="stories-story-items">
              {[...Array(3)].map((_, index) => (
                <div
                  key={index}
                  className="phn-white-card stories-story-item skeleton"
                  style={{
                    height: '60px',
                  }}
                ></div>
              ))}
            </div>
          ) : (
            <div className="stories-story-items">
              {stories.length > 0 &&
                stories.map((story) => (
                  <div
                    key={story.id}
                    className={`phn-white-card stories-story-item ${
                      story.status === 'Error'
                        ? 'error'
                        : story.status !== 'Complete'
                          ? 'disabled'
                          : ''
                    }`}
                    onClick={() => {
                      if (story.status === 'Complete') {
                        navigate(`/read-story`, {
                          state: { selectedStory: story.id },
                        });
                      }
                    }}
                  >
                    {story.native_language_title ? (
                      <div className="stories-story-title">
                        <span style={{ fontSize: '1.4rem' }}>
                          {story.target_language_title}
                        </span>
                        <span style={{ fontSize: '1rem' }}>
                          {story.native_language_title}
                        </span>
                      </div>
                    ) : (
                      <h3 className="stories-story-title">{story.theme}</h3>
                    )}
                    <div className="stories-status-and-actions">
                      {story.status !== 'Complete' && (
                        <h3
                          className={`stories-story-title ${story.status === 'Error' ? 'error' : ''}`}
                          style={{
                            color:
                              story.status === 'Error' ? '#dc3545' : '#dc6a35',
                          }}
                        >
                          {story.status}
                        </h3>
                      )}
                      {(story.status === 'Complete' ||
                        story.status === 'Error') && (
                        <div className="stories-item-buttons">
                          <button
                            className={`phn-icon-button phn-button-tooltip ${story.status === 'Error' ? 'error-trash' : ''}`}
                            tooltip-data="Delete Story"
                            onClick={(e) => {
                              e.stopPropagation();
                              setStoryToDelete(story);
                            }}
                          >
                            <FaTrash />
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
      {showCreatePopup && languageCode && (
        <CreateStoryPopup
          onClose={() => setShowCreatePopup(false)}
          onSubmit={submitStory}
        />
      )}
      {storyToDelete && (
        <ConfirmDeletePopup
          onClose={() => setStoryToDelete(null)}
          onConfirm={confirmDeleteStory}
          message={`Are you sure you want to delete the story "${storyToDelete.target_language_title}"?`}
        />
      )}
    </>
  );
};

export default StoriesPage;
