import React, { useState } from 'react';
import Footer from '../../../components/public/footer/Footer';
import LoginHeader from '../../../components/public/loginHeader/LoginHeader';
import './QuestionsPage.css'; // Import your CSS file for styling

const QuestionsPage: React.FC = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // Assuming you would handle form submission logic here
    // You can use a library like Axios to send a POST request to your backend
    // For demonstration purposes, I'm just logging the form data
    console.log({ name, email, message });
    // Clearing form fields after submission
    setName('');
    setEmail('');
    setMessage('');
  };

  return (
    <div className="questions-container">
      <LoginHeader />
      <main className="questions-main-content">
        <section className="questions-section">
          <h1 className="questions-title">Questions</h1>
          <p>
            Have a question? Feel free to reach out to us using the form below.
          </p>
        </section>
        <section className="questions-section">
          <form className="questions-form" onSubmit={handleSubmit}>
            <div className="questions-form-group">
              <label htmlFor="name">Your Name</label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div className="questions-form-group">
              <label htmlFor="email">Your Email</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="questions-form-group">
              <label htmlFor="message">Your Message</label>
              <textarea
                id="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="questions-submit-btn">
              Send Message
            </button>
          </form>
        </section>
        <section className="questions-section">
          <h2 className="questions-contact-info">Contact Us</h2>
          <p>
            If you have any urgent questions or issues, you can also email us
            directly at{' '}
            <a href="mailto:support@phonetica.ai">support@phonetica.ai</a>.
          </p>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default QuestionsPage;
