import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { useUser } from './UserContext';

interface PrivateRouteProps {
  children: React.ReactElement; // Ensures that only a single React element is passed
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const { isAuthenticated, isLoading: authLoading } = useAuth();
  const { userData, isLoading: userLoading, hasAccess } = useUser();
  const location = useLocation();

  // If still loading, return null or a loading spinner
  if (authLoading || userLoading || hasAccess === null) {
    return null;
  }

  // Check if the user is authenticated
  if (isAuthenticated === false && location.pathname !== '/login') {
    return <Navigate to="/login" replace />;
  }

  if (!hasAccess) {
    if (location.pathname !== '/payment') {
      return <Navigate to="/payment" replace />;
    }
  } else {
    if (location.pathname === '/payment') {
      return <Navigate to="/dashboard" replace />;
    }

    // Check if the user has selected a learning language
    const learningLanguageCode = userData?.learning_language_code;

    // If the user hasn't selected a learning language and is not on the language-select page, redirect to language-select
    if (learningLanguageCode === null || learningLanguageCode === 'null') {
      if (location.pathname !== '/language-select') {
        return <Navigate to="/language-select" replace />;
      }
    }
  }

  return children;
};

export default PrivateRoute;
