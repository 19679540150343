import axios from 'axios';
import React, { useCallback, useState } from 'react';
import { useDebounce } from '../../hooks/useDebounce';
import { Card } from '../../interfaces/cards';
import { getPhoneticName } from '../../utils/formatting';

interface EditCardPopupProps {
  card: Card;
  language_code: string;
  onClose: () => void; // Function to close the popup
  onSubmit: () => void; // Function to submit the popup
}

const EditCardPopup: React.FC<EditCardPopupProps> = ({
  card,
  language_code,
  onClose,
  onSubmit,
}) => {
  const [cardNativeLanguage, setCardNativeLanguage] = useState(
    card.native_language
  );
  const [cardTargetLanguage, setCardTargetLanguage] = useState(
    card.target_language
  );
  const [cardPhonetic, setCardPhonetic] = useState(card.phonetic);
  const [cardFormality, setCardFormality] = useState(card.formality);

  const handleSave = async () => {
    try {
      let cardData: Partial<Card> = {};

      if (cardNativeLanguage !== card.native_language) {
        cardData.native_language = cardNativeLanguage;
      }
      if (cardTargetLanguage !== card.target_language) {
        cardData.target_language = cardTargetLanguage;
      }
      if (cardFormality !== card.formality) {
        cardData.formality = cardFormality;
      }
      if (cardPhonetic !== card.phonetic) {
        cardData.phonetic = cardPhonetic;
      }

      if (Object.keys(cardData).length > 0) {
        await axios.put(`/api/cards/update/${card.id}`, cardData);
        onSubmit();
      } else {
        onClose();
      }
    } catch (error) {
      console.error('Error updating card:', error);
      onClose();
    }
  };

  const debouncedHandleSave = useDebounce(handleSave, 500);

  const debouncedUpdatePhonetic = useDebounce(async (value: string) => {
    try {
      const response = await axios.post('/api/generate/phonetics', {
        sentence: value,
        language_code: language_code,
      });
      const data = response.data;
      setCardPhonetic(data.phonetic);
    } catch (error) {
      console.error('Error fetching phonetic:', error);
    }
  }, 300);

  const handleTargetLanguageChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setCardTargetLanguage(e.target.value);
      if (cardPhonetic) {
        debouncedUpdatePhonetic(e.target.value);
      }
    },
    [cardPhonetic, debouncedUpdatePhonetic]
  );

  const formalities = ['casual', 'neutral', 'polite'];

  return (
    <div className="phn-popup-overlay">
      <div className="phn-white-card phn-popup" style={{ width: '500px' }}>
        <h2>Edit Card</h2>
        <div className="phn-popup-form">
          <label>
            Native Language
            <input
              id="nativeLanguage"
              type="text"
              value={cardNativeLanguage}
              onChange={(e) => setCardNativeLanguage(e.target.value)}
            />
          </label>

          <label htmlFor="targetLanguage">
            Target Language
            <input
              id="targetLanguage"
              type="text"
              value={cardTargetLanguage}
              onChange={handleTargetLanguageChange}
            />
          </label>

          {cardPhonetic && (
            <label htmlFor="phonetic">
              {getPhoneticName(language_code)}
              <input
                id="phonetic"
                type="text"
                value={cardPhonetic}
                onChange={(e) => setCardPhonetic(e.target.value)}
              />
            </label>
          )}
          <label htmlFor="formality">
            Formality
            <select
              id="language"
              value={cardFormality}
              onChange={(e) => setCardFormality(e.target.value)}
            >
              {formalities.map((formality, index) => (
                <option key={index} value={formality}>
                  {formality}
                </option>
              ))}
            </select>
          </label>

          <div className="phn-popup-button-container">
            <button className="phn-button" onClick={debouncedHandleSave}>
              Save
            </button>
            <button className="phn-cancel-button" onClick={onClose}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCardPopup;
