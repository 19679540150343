import axios from 'axios';
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { UserData } from '../interfaces/users';
import { useAuth } from './AuthContext';

interface UserContextTypeProps {
  userData: UserData | null;
  refreshUser: () => void;
  isLoading: boolean;
  hasAccess: boolean | null;
}

const UserContext = createContext<UserContextTypeProps | undefined>(undefined);

export const UserProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { isAuthenticated } = useAuth();
  const [userData, setUserData] = useState<UserData | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [hasAccess, setHasAccess] = useState<boolean | null>(null);

  const refreshUser = useCallback(async () => {
    try {
      const response = await axios.get('/api/users/whoami');
      const data = response.data;
      setUserData(data);
      setHasAccess(data.has_access);
      console.log('refreshed user');
    } catch (error) {
      console.error('Error fetching user data: ', error);
      setHasAccess(false);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      refreshUser();
    } else if (isAuthenticated === false) {
      setUserData(null);
      setHasAccess(false);
      setIsLoading(false);
    }
  }, [refreshUser, isAuthenticated]);

  const userContextValue = useMemo(
    () => ({
      userData,
      refreshUser,
      isLoading,
      hasAccess,
    }),
    [userData, refreshUser, isLoading, hasAccess]
  );

  return (
    <UserContext.Provider value={userContextValue}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
