import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EditableTranslation from '../../../components/private/editableTranslation/EditableTranslation';
import PlayButton from '../../../components/private/playButton/PlayButton';
import { Translation } from '../../../interfaces/cards';
import { Deck } from '../../../interfaces/decks';
import './AddCardPage.css';

const AddCardPage: React.FC = () => {
  const [deckId, setDeckId] = useState<number>();
  const [deck, setDeck] = useState<Deck | null>(null);
  const [phrase, setPhrase] = useState<string>('');
  const [lastTranslatedPhrase, setLastTranslatedPhrase] = useState<string>('');
  const [translations, setTranslations] = useState<Translation[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [audioLoading, setAudioLoading] = useState<boolean>(false);
  const [voiceEntries, setVoiceEntries] = useState<[string, unknown][]>([]);
  const [voices, setVoices] = useState<{ [key: string]: string }>({});
  const [selectedVoice, setSelectedVoice] = useState<string>('random');
  const [randomVoiceId, setRandomVoiceId] = useState<string | null>(null); // State for the random voice ID
  const [audioUrl, setAudioUrl] = useState<string | null>(null);
  const [audioSpeed, setAudioSpeed] = useState<number>(0.9);
  const location = useLocation();

  useEffect(() => {
    const state = location.state as { deckId?: number };
    if (location.state && state.deckId) {
      setDeckId(state.deckId);
    }
  }, [location.state]);

  const fetchDeck = useCallback(async () => {
    try {
      const response = await axios.get(`/api/decks/${deckId}`);
      const data = response.data;
      setDeck(data);
    } catch (error) {
      console.error('Error fetching deck details:', error);
    }
  }, [deckId]);

  // Fetch deck details
  useEffect(() => {
    if (deckId) {
      fetchDeck();
    }
  }, [fetchDeck, deckId]);

  const randomVoice = async () => {
    // Select a random voice ID if "Random" is selected
    if (voiceEntries.length > 0) {
      const randomIndex = Math.floor(Math.random() * voiceEntries.length);
      // Type assertion to cast the value to string
      setRandomVoiceId(voiceEntries[randomIndex][1] as string);
    }
  };

  const fetchVoices = useCallback(async () => {
    if (!deck?.language_code) {
      console.error('No language code found');
      return;
    }

    try {
      const response = await axios.get(
        `/api/audio/speech/voices/${deck.language_code}`
      );
      const data = response.data;
      const voiceEntries = Object.entries(data);
      setVoiceEntries(voiceEntries);

      // Add "Random" as the default option
      const updatedVoices = { Random: 'random', ...data };

      setVoices(updatedVoices);

      // Set the "Random" option as the selected voice by default
    } catch (error) {
      console.error('Error fetching voices:', error);
    }
  }, [deck?.language_code]);

  // Fetch voices only after deck details are loaded
  useEffect(() => {
    if (deck?.language_code) {
      fetchVoices();
    }
  }, [fetchVoices, deck?.language_code]);

  const handleTranslate = async () => {
    setTranslations([]);
    setAudioUrl(null); // Clear the audioUrl
    try {
      setLoading(true);

      const translateData = {
        sentence: phrase,
        language_code: deck?.language_code,
      };

      const response = await axios.post(
        '/api/generate/text/translations',
        translateData
      );

      const translatedResponse: Translation[] = response.data;

      const translationsWithIDs = translatedResponse.map((item, index) => ({
        ...item,
        id: index,
        selected: false,
      }));

      setTranslations(translationsWithIDs);
      setLastTranslatedPhrase(phrase); // Update last translated phrase

      if (selectedVoice === 'random') {
        randomVoice();
      }
    } catch (error: any) {
      console.error('Error translating phrase:', error);
      toast.error(`Error: ${error.response.data.detail}`);
    } finally {
      setLoading(false);
    }
  };

  const handleCheckboxChange = (id: number) => {
    const updatedTranslations = translations.map((translation) =>
      translation.id === id
        ? { ...translation, selected: !translation.selected }
        : translation
    );
    setTranslations(updatedTranslations);
    setAudioUrl(null);
  };

  const handleSaveTranslation = async (id: number, newTranslation: string) => {
    if (newTranslation !== translations[id].translation) {
      try {
        // First, check if phonetic needs to be updated
        const updatePhonetic = translations.find((t) => t.id === id)?.phonetic;

        if (updatePhonetic) {
          const response = await axios.post('/api/generate/phonetics', {
            sentence: newTranslation,
            language_code: deck?.language_code,
          });
          const data = response.data;

          // Update both translation and phonetic in one state update
          setTranslations(
            translations.map((translation) =>
              translation.id === id
                ? {
                    ...translation,
                    translation: newTranslation,
                    phonetic: data.phonetic,
                  }
                : translation
            )
          );
        } else {
          // Update only the translation if phonetic does not need updating
          setTranslations(
            translations.map((translation) =>
              translation.id === id
                ? {
                    ...translation,
                    translation: newTranslation,
                  }
                : translation
            )
          );
        }
      } catch (error: any) {
        console.error('Failed to save translation:', error);
        try {
          toast.error(`Error: ${error.response.data.detail}`);
        } catch (error: any) {
          toast.error('An error occurred while saving the translation.');
        }
      }
    }
  };

  const handleAddCards = async () => {
    const selectedItems = translations.filter(
      (translation) => translation.selected
    );

    if (selectedItems.length === 0) {
      toast.warning('Please select at least one card to add.');
      return;
    }

    try {
      const requestBody = selectedItems.map((item) => ({
        deck_id: deck?.id,
        native_language: phrase,
        target_language: item.translation,
        phonetic: item.phonetic,
        formality: item.formality,
        voice_id: selectedVoice === 'random' ? randomVoiceId : selectedVoice,
        speed: audioSpeed,
      }));

      await axios.post(`/api/decks/${deckId}/add_cards`, requestBody);
      toast.success('Card Added!', { autoClose: 3000 });
    } catch (error: any) {
      try {
        toast.error(`Error adding cards: ${error.response.data.detail}`);
      } catch (error: any) {
        toast.error('An error occurred while adding the cards.');
      }
    } finally {
      // Reset state after attempting to add cards
      setPhrase('');
      setTranslations([]);
      setAudioUrl(null); // Clear the audioUrl
    }
  };

  const handlePlay = async () => {
    console.log('handlePlay called');
    if (
      !selectedVoice ||
      !translations.some((translation) => translation.selected)
    ) {
      throw new Error('No voice or translation selected');
    }

    const selectedTranslation = translations.find(
      (translation) => translation.selected
    );

    if (!selectedTranslation) throw new Error('No translation selected');

    if (!audioUrl) {
      console.log('audioUrl is null');
      setAudioLoading(true);
      try {
        const response = await axios.post('/api/audio/generate_speech', {
          text: selectedTranslation.translation,
          voice_id: selectedVoice === 'random' ? randomVoiceId : selectedVoice,
          language_code: deck?.language_code,
          speed: audioSpeed, // Add the speed parameter
        });
        setAudioUrl(response.data);
      } catch (error) {
        console.error('Error generating speech:', error);
        toast.error('Failed to generate speech');
        throw error;
      } finally {
        setAudioLoading(false);
      }
    }
  };

  const handleVoiceChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newVoice = e.target.value;
    console.log('newVoice', newVoice);
    setSelectedVoice(newVoice);
    setAudioUrl(null);
    if (newVoice === 'random') {
      randomVoice();
    } else {
      setRandomVoiceId(null); // Clear the random voice when a specific voice is selected
    }
  };

  const handleSpeedChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setAudioSpeed(Number(e.target.value));
    setAudioUrl(null); // Clear the audioUrl when speed changes
  };

  if (!deckId || !deck) {
    return (
      <div className="add-card-loading">
        <div className="loading-spinner"></div>
      </div>
    );
  }

  return (
    <div className="phn-page-container">
      <h1>Add Card to {deck.name}</h1>
      <div className="phn-main-content">
        <div className="input-container">
          <input
            type="text"
            id="phrase-input"
            placeholder="Phrase to translate..."
            value={phrase}
            onChange={(e) => setPhrase(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleTranslate();
              }
            }}
            className="phrase-input"
            disabled={loading}
          />
          <button
            onClick={handleTranslate}
            className="phn-button"
            disabled={loading || !phrase || phrase === lastTranslatedPhrase}
          >
            {loading ? 'Translating...' : 'Translate'}
          </button>
        </div>
        {translations.length > 0 && (
          <>
            <h3>Select cards to add:</h3>
            <div className="translation-list">
              {translations.map((translation) => (
                <div
                  key={translation.id}
                  className={`phn-white-card translation-item ${translation.selected ? 'selected' : ''}`}
                  onClick={() => handleCheckboxChange(translation.id)}
                >
                  <div className="translation-details">
                    <EditableTranslation
                      translation={translation.translation}
                      onSave={(newTranslation) =>
                        handleSaveTranslation(translation.id, newTranslation)
                      }
                    />
                    <div className="phonetic">{translation.phonetic}</div>
                    <div className="formality">{translation.formality}</div>
                  </div>
                </div>
              ))}
            </div>
            <div className="voice-dropdowns">
              <label htmlFor="voice-dropdown">Voice:</label>
              <select
                id="voice-dropdown"
                value={selectedVoice}
                onChange={handleVoiceChange}
                disabled={loading}
                className="voice-dropdown"
              >
                {Object.entries(voices).map(([key, value]) => (
                  <option key={key} value={value}>
                    {key}
                  </option>
                ))}
              </select>
              <select
                id="speed-dropdown"
                value={audioSpeed.toString()} // Convert to string to match option values
                onChange={handleSpeedChange}
                disabled={loading}
                className="speed-dropdown"
              >
                <option value={0.5}>0.5x</option>
                <option value={0.6}>0.6x</option>
                <option value={0.7}>0.7x</option>
                <option value={0.8}>0.8x</option>
                <option value={0.9}>0.9x</option>
                <option value={1.0}>1.0x</option>
              </select>
              <PlayButton
                src={audioUrl}
                onPlay={handlePlay}
                disabled={
                  !translations.some((translation) => translation.selected)
                }
                isLoading={audioLoading}
                autoPlay={true}
              />
            </div>
            <button
              onClick={handleAddCards}
              className="add-cards-button"
              disabled={
                !translations.some((translation) => translation.selected)
              }
            >
              Add Card
              {translations.filter((translation) => translation.selected)
                .length > 1
                ? 's'
                : ''}
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default AddCardPage;
