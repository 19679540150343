import axios from 'axios';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import './CreateStoryPopup.css'; // Importing CSS for the popup

interface CreateStoryPopupProps {
  onClose: () => void; // Function to close the popup
  onSubmit: () => void; // Function to submit the popup
}

const CreateStoryPopup: React.FC<CreateStoryPopupProps> = ({
  onSubmit,
  onClose,
}) => {
  const [theme, setTheme] = useState<string>(''); // State for story name
  const [vocab, setVocab] = useState<number>(0); // State for story name
  const [difficulty, setDifficulty] = useState<string>('beginner'); // State for story name

  const handleCreateStory = async () => {
    try {
      // Define the payload with optional new_limit and review_limit
      const payload = {
        theme: theme,
        n_vocab: vocab,
        difficulty: difficulty,
      };
      await axios.post('/api/stories/create', payload);
      onSubmit();
    } catch (error: any) {
      console.error('Error creating story:', error);
      toast.error(`Error: ${error.response.data.detail}`);
      onClose();
    }
  };

  return (
    <div className="phn-popup-overlay">
      <div className="phn-white-card phn-popup" style={{ width: '500px' }}>
        <h2>Create New Story</h2>
        <div className="phn-popup-form">
          <label htmlFor="name">
            Theme (optional)
            <input
              id="theme"
              type="text"
              value={theme}
              onChange={(e) => setTheme(e.target.value)}
            />
          </label>
          <label htmlFor="name">
            Vocab
            <div className="create-story-selections">
              <div
                className={`create-story-selections-item ${vocab === 0 ? 'active' : ''}`}
                onClick={() => {
                  setVocab(0);
                }}
              >
                My Vocab
              </div>
              <div
                className={`create-story-selections-item ${vocab === 50 ? 'active' : ''}`}
                onClick={() => {
                  setVocab(50);
                }}
              >
                50 Words
              </div>
              <div
                className={`create-story-selections-item ${vocab === 100 ? 'active' : ''}`}
                onClick={() => {
                  setVocab(100);
                }}
              >
                100 Words
              </div>
              <div
                className={`create-story-selections-item ${vocab === 200 ? 'active' : ''}`}
                onClick={() => {
                  setVocab(200);
                }}
              >
                200 Words
              </div>
            </div>
          </label>
          <label htmlFor="name">
            Difficulty
            <div className="create-story-selections">
              <div
                className={`create-story-selections-item ${difficulty === 'beginner' ? 'active' : ''}`}
                onClick={() => {
                  setDifficulty('beginner');
                }}
              >
                Beginner
              </div>
              <div
                className={`create-story-selections-item ${difficulty === 'intermediate' ? 'active' : ''}`}
                onClick={() => {
                  setDifficulty('intermediate');
                }}
              >
                Intermediate
              </div>
              <div
                className={`create-story-selections-item ${difficulty === 'advanced' ? 'active' : ''}`}
                onClick={() => {
                  setDifficulty('advanced');
                }}
              >
                Advanced
              </div>
            </div>
          </label>
        </div>
        <div className="phn-popup-button-container">
          <button className="phn-button" onClick={handleCreateStory}>
            Create
          </button>
          <button className="phn-cancel-button" onClick={onClose}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateStoryPopup;
