import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../../context/UserContext';
import { Language } from '../../../interfaces/language';
import './LanguageSelect.css';

const LanguageSelect: React.FC = () => {
  const { refreshUser, userData } = useUser();
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [languages, setLanguages] = useState<Language[]>([]);
  const navigate = useNavigate();

  const fetchLanguages = useCallback(async () => {
    try {
      const response = await axios.get('/api/languages/supported');
      const data = response.data;
      const languagesArray: Language[] = Object.keys(data).map((code) => ({
        code,
        name: data[code],
      }));
      setLanguages(languagesArray);
    } catch (error) {
      console.error('Error fetching languages:', error);
    }
  }, []);

  useEffect(() => {
    if (userData?.learning_language_code) {
      navigate('/dashboard');
    }
  }, [navigate, userData?.learning_language_code]);

  useEffect(() => {
    fetchLanguages();
  }, [fetchLanguages]);

  const handleLanguageSelect = async (code: string) => {
    setSelectedLanguage(code);
  };

  const handleSumbit = async () => {
    try {
      await axios.put(
        `/api/users/update/learning_language/${selectedLanguage}`
      );
      refreshUser();
    } catch (error) {
      console.error('Error updating learning languages:', error);
    }
  };

  return (
    <div className="language-select-overlay">
      <div className="language-select-container">
        <h2>What language are you learning?</h2>
        <div className="language-selection">
          {languages.map((lang) => (
            <div
              className={`language-select-language-choice${lang.code === selectedLanguage ? '-selected' : ''}`}
              onClick={(e) => {
                handleLanguageSelect(lang.code);
              }}
            >
              <img
                className="language-select-language-flag-img"
                src={`/assets/flags/${lang.code}.svg`}
                alt={`${lang.code} flag`}
              />
              <span className="language-select-language-name">{lang.name}</span>
            </div>
          ))}
        </div>
        <button
          className="language-select-sumbit-button"
          disabled={!selectedLanguage}
          onClick={handleSumbit}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default LanguageSelect;
