import { useStripe } from '@stripe/react-stripe-js';
import axios from 'axios';
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { useDebounce } from '../../../hooks/useDebounce';
import './PaymentPage.css';

const PaymentPage: React.FC = () => {
  const stripe = useStripe();
  const [isProcessing, setIsProcessing] = useState(false);

  const handlePayment = async (planType: string) => {
    if (!stripe) {
      console.error('Stripe has not loaded');
      return;
    }

    setIsProcessing(true);

    try {
      const response = await axios.post(
        '/api/payment/create_checkout_session',
        {
          plan_type: planType,
        }
      );

      const session = response.data;
      window.location.href = session.url;
    } catch (error) {
      console.error('Error creating checkout session:', error);
      // Handle error (e.g., show error message to user)
    } finally {
      setIsProcessing(false);
    }
  };

  const debouncedHandlePayment = useDebounce(handlePayment, 300);

  const renderPaymentCard = (
    planType: string,
    title: string,
    price: string,
    originalPrice?: string,
    savings?: string,
    featured: boolean = false
  ) => (
    <motion.div
      className={`payment-card ${featured ? 'payment-featured' : ''}`}
      whileHover={{ scale: 1.03 }}
      transition={{ type: 'tween', duration: 0.3 }}
    >
      {featured && <div className="payment-featured-label">Best Value</div>}
      <h2>{title}</h2>
      <div className="payment-price-container">
        {originalPrice && (
          <p className="payment-original-price">{originalPrice}</p>
        )}
        <p className="payment-price">{price}</p>
        <p className="payment-price-per-month">
          $
          {(
            parseFloat(price.replace('$', '')) /
            (title === 'Monthly' ? 1 : parseInt(title))
          ).toFixed(2)}
          <span>/month</span>
        </p>
      </div>
      {savings && <p className="payment-savings">{savings}</p>}
      <motion.button
        className="payment-button"
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onClick={() => debouncedHandlePayment(planType)}
        disabled={isProcessing}
      >
        Select Plan
      </motion.button>
    </motion.div>
  );

  return (
    <div className="payment-container">
      <motion.h1
        className="payment-title"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
      >
        Choose Your Plan
      </motion.h1>
      <motion.p
        className="payment-free-trial-text"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.6, delay: 0.3 }}
      >
        All plans come with a 7-day free trial
      </motion.p>
      <motion.div
        className="payment-cards"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.6, delay: 0.4 }}
      >
        {renderPaymentCard(
          'monthly',
          'Monthly',
          '$10',
          undefined,
          undefined,
          false
        )}
        {renderPaymentCard(
          '12-months',
          '12 Months',
          '$60',
          '$120',
          'Save $60 per year',
          true
        )}
        {renderPaymentCard(
          '6-months',
          '6 Months',
          '$40',
          '$60',
          undefined,
          false
        )}
      </motion.div>
    </div>
  );
};

export default PaymentPage;
